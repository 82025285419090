import PropTypes from "prop-types";
import { useEffect, useRef } from "react";
import IconBoxData from "../../data/iconBox/icon-box2.json";
import IconBox2 from "../../components/IconBox/IconBox2.jsx";
import SectionTitle from "../../components/SectionTitles/SectionTitle";
import Parallax from "parallax-js";

const ServiceIconBoxTwo = ({ classOption }) => {
	const sceneEl = useRef(null);
	useEffect(() => {
		const parallaxInstance = new Parallax(sceneEl.current, {
			relativeInput: true,
		});

		parallaxInstance.enable();

		return () => parallaxInstance.disable();
	}, []);
	return (
		<div
			className={`section section-padding-t90 section-padding-bottom ${classOption}`}
		>
			<div className="container">
				<SectionTitle
					headingOption="fz-32"
					title="JCI Монгол Гишүүдийн Апп"
					subTitle="JCI Mongolia байгууллагын гишүүн бүрт эерэг туршлагыг бүтээж байгууллагын дотоод үйл ажиллагаа, 
					гишүүд хоорондын харилцаа холбоо, хамтын ажиллагааг хялбарчлана"
				/>

				<div className="row row-cols-lg-5 row-cols-md-3 row-cols-sm-2 row-cols-1 mb-n6">
					{IconBoxData &&
						IconBoxData.map((single, key) => {
							return (
								<div
									key={key}
									className="col mb-6"
									data-aos="fade-up"
									data-aos-delay="300"
								>
									<IconBox2 classOption="box-border" data={single} key={key} />
								</div>
							);
						})}

					<div className="shape shape-1" id="scene" ref={sceneEl}></div>
				</div>
			</div>
		</div>
	);
};

ServiceIconBoxTwo.propTypes = {
	classOption: PropTypes.string,
};
ServiceIconBoxTwo.defaultProps = {
	classOption: "section section-padding-t90 section-padding-bottom",
};

export default ServiceIconBoxTwo;
