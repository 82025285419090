import React from 'react';
import SEO from '../components/SEO';
import Header from "../partials/header/Header";
import Breadcrumb from '../container/Breadcrumb/Breadcrumb';
import PageBlog from '../container/BlogGrid/PageBlog';
import Footer from '../container/Footer/Footer';
import ScrollToTop from '../components/ScrollToTop.jsx';


const BlogGrid = () => {
    return (
        <React.Fragment>
            <SEO title="JCI Монгол байгууллага" />
            <Header />
            <Breadcrumb 
                image="images/bg/jci-mongolia.png"
                title="JCI МОНГОЛ БАЙГУУЛЛАГА"
                content="Home"
                contentTwo="JCI Монгол байгууллага"
            />
            <PageBlog />
            <Footer />
            <ScrollToTop />
        </React.Fragment>
    )
}

export default BlogGrid;
