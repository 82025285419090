import PropTypes from "prop-types";
import React from "react";
import JciDetail from "../../components/Blog/JciDetail.jsx";
import SidebarWrap from "../../components/Sidebar/SidebarWrap.jsx";

const JciDetailContainer = ({ data }) => {
	return (
		<div className="section section-padding fix">
			<div className="container">
				<div className="row mb-n10">
					<div className="col-lg-8 col-12 order-lg-1 mb-10">
						<div className="row row-cols-1 no-gutters">
							<JciDetail data={data} />
						</div>
					</div>
					{data && data.title && data.title.rendered === "JCI" && (
						<div className="col-lg-4 col-12 order-lg-2 mb-10">
							<SidebarWrap>
								<div className="social-media">
									<span className="small-caption">Бидэнтэй холбогдох:</span>
									<ul className="social-icons">
										<li className="facebook">
											<a
												href="https://www.facebook.com/jcimongolia"
												target="_blank"
												rel="noopener noreferrer"
											>
												<i className="fab fa-facebook-square fa-2x" />
											</a>
										</li>
										<li className="twitter">
											<a
												href="https://twitter.com/jcimongolia"
												target="_blank"
												rel="noopener noreferrer"
											>
												<i className="fab fa-twitter-square fa-2x" />
											</a>
										</li>
										<li className="youtube">
											<a
												href="https://www.youtube.com/channel/UCbaIweQnTJBQ5ZpUibjlCeA"
												target="_blank"
												rel="noopener noreferrer"
											>
												<i className="fab fa-youtube fa-2x" />
											</a>
										</li>
										<li className="email">
											<a
												href="mailto:sg@jcimongolia.mn"
												target="_blank"
												rel="noopener noreferrer"
											>
												<i className="fas fa-envelope-square fa-2x" />
											</a>
										</li>
									</ul>
								</div>
								<div className="jci-youtube">
									<iframe
										id="player"
										type="text/html"
										width="400"
										height="300"
										src="//www.youtube.com/embed/Ym0NHjSzwJk?wmode=transparent"
										frameBorder="0"
										className="jci-youtube-video"
									/>
								</div>
								<div className="facebook-widget">
									<iframe
										src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com/jcimongolia%2Ffacebook&tabs=timeline&width=340&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId"
										width="340"
										height="500"
										style={{
											border: "none",
											overflow: "hidden",
											marginTop: 60,
										}}
										scrolling="no"
										frameBorder="0"
										allowFullScreen={true}
										allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
									/>
								</div>
							</SidebarWrap>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

JciDetailContainer.propTypes = {
	data: PropTypes.object,
};

export default JciDetailContainer;
