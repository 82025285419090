import React, { useState, useContext } from "react";
import SectionTitle from "../../components/SectionTitles/SectionTitle";
import WorkItemTwo from "../../components/Work/WorkItemTwo.jsx";
import { DataContext } from "../../context";

const WorkContainer = () => {
	const value = useContext(DataContext);
	const [search, setSearch] = useState("");
	const [searchParam] = useState(["rendered"]);
	const [filter, setFilter] = useState("All");
	const filterSelections = [
		{ id: 0, name: "JCI" },
		{ id: 1, name: "JCI APDC (Asia Pacific Development Council)" },
		{ id: 2, name: "JCI Capital" },
		{ id: 3, name: "JCI Central" },
		{ id: 4, name: "JCI Chairman" },
		{ id: 5, name: "JCI Congress" },
		{ id: 6, name: "JCI Darkhan" },
		{ id: 7, name: "JCI Eco" },
		{ id: 8, name: "JCI Erdenet" },
		{ id: 9, name: "JCI Grand" },
		{ id: 10, name: "JCI Ikh Khuree" },
		{ id: 11, name: "JCI Impact" },
		{ id: 12, name: "JCI Leader" },
		{ id: 13, name: "JCI MDC" },
		{ id: 14, name: "JCI Mongolia" },
		{ id: 15, name: "JCI Nairamdal" },
		{ id: 16, name: "JCI Nomadic" },
		{ id: 17, name: "JCI Progress" },
		{ id: 18, name: "JCI Senator" },
		{ id: 19, name: "JCI Ulaanbaatar" },
	];
	
	const searchQ = (value) => {
		return value.filter((item) => {
			if (item && item.acf.category === filter) {
				return searchParam.some((newItem) => {
					return (
						item.title[newItem]
							.toString()
							.toLowerCase()
							.indexOf(search.toLowerCase()) > -1
					);
				});
			} else if (item && item.acf.branch === filter) {
				return searchParam.some((newItem) => {
					return (
						item.title[newItem]
							.toString()
							.toLowerCase()
							.indexOf(search.toLowerCase()) > -1
					);
				});
			} else if (filter === "All") {
				return searchParam.some((newItem) => {
					return (
						item.title[newItem]
							.toString()
							.toLowerCase()
							.indexOf(search.toLowerCase()) > -1
					);
				});
			}
		});
	};

	return (
		<div className="section section-padding-t90-b100">
			<div className="container">
				<SectionTitle
					headingOption="title fz-32"
					title="JCI Монгол байгууллага нийгэмд байнгын тогтвортой нөлөөллийг бий болгоход дэмжин ажиллана"
				/>
				<form style={{ marginBlock: 50, position: "relative" }}>
					<i className="fas fa-search" />
					<input
						placeholder="search for..."
						value={search}
						onChange={(e) => setSearch(e.target.value)}
						className="project-search-input"
					/>
					<select
						onChange={(e) => setFilter(e.target.value)}
						className="project-filter-select"
					>
						<option value="All">Салбар сонгох...</option>
						{filterSelections.map((selection) => {
							return (
								<option key={selection.id} value={selection.name}>
									{selection.name}
								</option>
							);
						})}
					</select>
					<select
						onChange={(e) => setFilter(e.target.value)}
						className="project-filter-select"
					>
						<option value="All">Төрөл</option>
						<option value="Сайн дурын">Сайн дурын</option>
					</select>
				</form>
				<div className="row row-cols-lg-3 row-cols-md-2 row-cols-1 mb-n6">
					{searchQ(value.projectPosts).map((single) => {
						return (
							<div key={single.id} data-aos="fade-up" data-aos-delay="100">
								<WorkItemTwo classOption="box-border" data={single} />
							</div>
						);
					})}
				</div>
			</div>
		</div>
	);
};

export default WorkContainer;
