import React, { useContext } from "react";
import SEO from "../components/SEO";
import Header from "../partials/header/Header";
import Breadcrumb from "../container/Breadcrumb/Breadcrumb";
import JciDetailContainer from "../container/BlogGrid/JciDetailContainer";
import Footer from "../container/Footer/Footer";
import ScrollToTop from "../components/ScrollToTop.jsx";
import { DataContext } from "../context";

const JciCorporate = () => {
	const value = useContext(DataContext);
	// const data = value.jciCorPost.filter((item) => item.title.rendered === "JCI");

	return (
		<>
			<SEO title="JCI байгууллага" />
			<Header />
			<Breadcrumb
				image="images/bg/jci-mongolia.png"
				title="JCI БАЙГУУЛЛАГА"
				content="Home"
				contentTwo="JCI байгууллага"
			/>
			<JciDetailContainer data={value.jciCorPost[0]} />
			<Footer />
			<ScrollToTop />
		</>
	);
};

export default JciCorporate;
