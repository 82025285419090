import React from "react";
import PropTypes from "prop-types";
import { getData } from "../../utils/getData";

const JciDetail = ({ data }) => {
	return (
		<div className="blog-3 blog-details col" data-aos="fade-up">
			<div className="thumbnail">
				<img
					className="w-100"
					src={`${getData(data && data._embedded, "image")}`}
					alt="Blog Image"
				/>
			</div>
			<div className="info">
				<h3 className="title title-margin">
					{data && data.title && data.title.rendered}
				</h3>
				<p className="sub-title" style={{ fontWeight: "bold", fontSize: 18 }}>
					{data && data.acf && data.acf.subtitle}
				</p>
				<p className="contact">Утас: {data && data.acf && data.acf.number}</p>
				<p className="established-date">
					Байгуулагдсан огноо: {data && data.acf && data.acf.establisheddate}
				</p>
				<h4 className="introduction">Танилцуулга</h4>
				<div
					dangerouslySetInnerHTML={{
						__html: data && data.content && data.content.rendered,
					}}
				/>
			</div>
		</div>
	);
};

JciDetail.propTypes = {
	data: PropTypes.object,
};

export default JciDetail;
