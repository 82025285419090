import React, { useContext } from "react";
import BlogItem from "../../components/Blog/BlogItem";
import { DataContext } from "../../context";

const PageBlog = () => {
	const value = useContext(DataContext);

	return (
		<div className="section section-padding fix bg-color-1">
			<div className="container">
				<div className="row row-cols-lg-3 row-cols-md-2 row-cols-sm-2 row-cols-1 mb-n6">
					{value &&
						value.jciMonCorPost.map((single) => {
							return (
								<div key={single.id} className="col mb-6" data-aos="fade-up">
									<BlogItem data={single} />
								</div>
							);
						})}
				</div>
			</div>
		</div>
	);
};

export default PageBlog;
