import React from "react";
import PropTypes from "prop-types";
import { getData } from "../../utils/getData";

const BlogDetails = ({ data }) => {
	return (
		<div className="blog-3 blog-details col" data-aos="fade-up">
			<div className="thumbnail">
				<img
					className="w-100"
					src={`${getData(data && data._embedded, "image")}`}
					alt="Blog Image"
				/>
			</div>
			<div className="info">
				<h3 className="title title-margin">
					<div
						dangerouslySetInnerHTML={{ __html: data && data.title.rendered }}
					/>
				</h3>
				<div
					dangerouslySetInnerHTML={{ __html: data && data.content.rendered }}
				/>
				<ul className="meta mb-0 mt-12">
					<li>
						<i className="far fa-calendar"></i>
						{data && data.acf.date}
					</li>
				</ul>
			</div>
		</div>
	);
};

BlogDetails.propTypes = {
	data: PropTypes.object,
};

export default BlogDetails;
