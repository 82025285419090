import PropTypes from "prop-types";
import React from "react";
import ReactVivus from "react-vivus";

const IconBox = ({ data, classOption }) => {
	return (
		<div className={`box-width text-center ${classOption}`}>
			<div className="icon">
				<ReactVivus
					id={`servicesvg-${data.id}`}
					option={{
						file: data.icon,
						animTimingFunction: "EASE",
						type: "oneByOne",
						delay: 80,
					}}
				/>
			</div>
			<div className="content">
				<h3 className="title">{data.title}</h3>
			</div>
		</div>
	);
};

IconBox.propTypes = {
	data: PropTypes.object,
	classOption: PropTypes.string,
};

IconBox.defaultProps = {
	classOption: "box-width text-center",
};

export default IconBox;
