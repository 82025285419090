import React from "react";
import PropTypes from "prop-types";
import { getData } from "../../utils/getData";

const EventDetail = ({ data }) => {
  return (
    <div className="blog-3 blog-details col" data-aos="fade-up">
      <div className="thumbnail">
        <img
          className="w-100"
          src={`${getData(data && data._embedded, "image")}`}
          alt="Blog Image"
        />
      </div>
      <div className="info">
        <h3 className="title title-margin">
          {data && data.title && data.title.rendered}
        </h3>
        <p className="sub-title" style={{ fontWeight: "bold", fontSize: 18 }}>
          {data && data.acf && data.acf.subtitle}
        </p>
        <div
          dangerouslySetInnerHTML={{
            __html: data && data.content && data.content.rendered,
          }}
        />
      </div>
    </div>
  );
};
EventDetail.propTypes = {
  data: PropTypes.object,
};

export default EventDetail;
