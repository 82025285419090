import React from "react";
import SEO from "../components/SEO";
import Header from "../partials/header/Header";
import Breadcrumb from "../container/Breadcrumb/Breadcrumb";
import ServiceIconBoxTwo from "../container/service/ServiceIconBoxTwo";
import AboutOne from "../container/About/AboutOne";
import AboutTwo from "../container/About/AboutTwo";
import AboutThree from "../container/About/AboutThree";
import AboutFive from "../container/About/AboutFive";
import Footer from "../container/Footer/Footer";
import ScrollToTop from "../components/ScrollToTop.jsx";

const Service = () => {
	return (
		<React.Fragment>
			<SEO title="Service" />
			<Header />
			<Breadcrumb
				image="images/bg/app-3.jpg"
				title="JCI Монгол Гишүүдийн Апп"
				content="Home"
				contentTwo="Гишүүдийн Апп"
			/>
			<ServiceIconBoxTwo />
			<AboutFive />
			<AboutOne />
			<AboutThree />
			<AboutTwo />
			<Footer />
			<ScrollToTop />
		</React.Fragment>
	);
};

export default Service;
