import React, { useContext } from "react";
import SEO from "../components/SEO";
import PropTypes from "prop-types";
import Header from "../partials/header/Header";
import Breadcrumb from "../container/Breadcrumb/Breadcrumb";
import JciDetailContainer from "../container/BlogGrid/JciDetailContainer";
import Footer from "../container/Footer/Footer";
import ScrollToTop from "../components/ScrollToTop.jsx";
import { DataContext } from "../context";

const CorporateDetails = ({
	match: {
		params: { id },
	},
}) => {
	const value = useContext(DataContext);
	const blogId = parseInt(id, 10);
	const data = value.jciMonCorPost.filter((blog) => blog.id === blogId);
	return (
		<>
			<SEO title="JCI Монгол байгууллага дэлгэрэнгүй" />
			<Header />
			<Breadcrumb
				image="images/bg/jci-mongolia.png"
				title={data[0]?.title.rendered}
				content="Home"
				contentTwo="JCI Монгол байгууллага дэлгэрэнгүй"
			/>
			<JciDetailContainer data={data[0]} />
			<Footer />
			<ScrollToTop />
		</>
	);
};

CorporateDetails.propTypes = {
	match: PropTypes.shape({
		params: PropTypes.shape({
			id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		}),
	}),
};

export default CorporateDetails;
