import PropTypes from "prop-types";
import React from "react";

const Pagination = ({ postsPerPage, totalPosts, paginate }) => {
	const pageNumbers = [];
	for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
		pageNumbers.push(i);
	}

	const onClickNumber = (number) => {
		paginate(number);
		window.scrollTo({ top: 500, behavior: "smooth" });
	};

	return (
		<div className="row">
			<div className="col">
				<ul className="pagination center">
					{pageNumbers.map((number) => (
						<li key={number} className="active">
							<a onClick={() => onClickNumber(number)}>{number}</a>
						</li>
					))}
				</ul>
			</div>
		</div>
	);
};

Pagination.propTypes = {
	postsPerPage: PropTypes.array,
	totalPosts: PropTypes.number,
	paginate: PropTypes.func,
};

export default Pagination;
