import PropTypes from "prop-types";
import React from "react";
import EventDetail from "../components/Event/EventDetail";

const EventDetailContainer = ({ data }) => {
  return (
    <div className="section section-padding fix">
      <div className="container">
        <div className="row mb-n10">
          <div className="col-lg-8 col-12 order-lg-1 mb-10">
            <div className="row row-cols-1 no-gutters">
              <EventDetail data={data} />
            </div>
          </div>
          {data && data.title && data.title.rendered === "JCI" && (
            <div className="col-lg-4 col-12 order-lg-2 mb-10"></div>
          )}
        </div>
      </div>
    </div>
  );
};

EventDetailContainer.propTypes = {
  data: PropTypes.object,
};

export default EventDetailContainer;
