import React, { useContext } from "react";
import SEO from "../components/SEO";
import PropTypes from "prop-types";
import Header from "../partials/header/Header";
import Breadcrumb from "../container/Breadcrumb/Breadcrumb";
import Footer from "../container/Footer/Footer";
import WorkDetailsContainer from "../container/Work/WorkDetailsContainer";
import ScrollToTop from "../components/ScrollToTop.jsx";
import { DataContext } from "../context";

const WorkDetails = ({
	match: {
		params: { id },
	},
}) => {
	const value = useContext(DataContext);
	const workId = parseInt(id, 10);
	const data = value.projectPosts.filter((work) => work.id === workId);

	return (
		<React.Fragment>
			<SEO title="Төсөл хөтөлбөр дэлгэрэнгүй" />
			<Header />
			<Breadcrumb
				image="images/bg/sticky-note.jpg"
				title={data[0]?.title.rendered}
				content="Home"
				contentTwo="Төсөл хөтөлбөр дэлгэрэнгүй"
			/>
			<WorkDetailsContainer data={data[0]} />
			<Footer />
			<ScrollToTop />
		</React.Fragment>
	);
};

WorkDetails.propTypes = {
	match: PropTypes.shape({
		params: PropTypes.shape({
			id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		}),
	}),
};

export default WorkDetails;
