import { useState, useEffect, useRef } from "react";
import SectionTitleTwo from "../SectionTitles/SectionTitleTwo";
import Tilt from "react-parallax-tilt";
import Parallax from "parallax-js";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
import { Link } from "react-router-dom";

const HomeAboutTwo = () => {
	const [didViewCountUp, setDidViewCountUp] = useState(false);
	const [scale] = useState(1.04);
	const sceneEl = useRef(null);

	const onVisibilityChange = (isVisible) => {
		if (isVisible) {
			setDidViewCountUp(true);
		}
	};

	useEffect(() => {
		const parallaxInstance = new Parallax(sceneEl.current, {
			relativeInput: true,
		});

		parallaxInstance.enable();

		return () => parallaxInstance.disable();
	}, []);

	return (
		<div className="section section-padding-top section-padding-bottom-150">   
			<div className="container">
				<div className="row">
					<div className="col-xl-6 col-lg-6 col-12" data-aos="fade-up">
						<div className="about-content-area mt-0 mb-md-10 mb-10">
							<SectionTitleTwo title="Эерэг өөрчлөлт бүтээх хөгжлийн боломжийг залуучуудад олгох" />

							<div className="row mb-n6">
								<div className="col-md-5 col-sm-6 col-12 mb-6">
									<div className="about-funfact">
										<div className="number">
											<VisibilitySensor
												onChange={onVisibilityChange}
												offset={{ top: 10 }}
												delayedCall
											>
												<CountUp end={didViewCountUp ? 170 : 0} />
											</VisibilitySensor>
											+
										</div>
										<h6 className="text">Төсөл</h6>
										<p> JCI байгууллагa нь 170 төсөлд ажиллаж байсан </p>
									</div>
								</div>
								<div className="col-md-5 col-sm-6 col-12 mb-6">
									<div className="about-funfact">
										<div className="number">
											<VisibilitySensor
												onChange={onVisibilityChange}
												offset={{ top: 10 }}
												delayedCall
											>
												<CountUp end={didViewCountUp ? 1000 : 0} />
											</VisibilitySensor>
											+
										</div>
										<h6 className="text">Гишүүд</h6>
										<p>JCI байгууллагa нь 1000 -аас дээш гишүүдтэй</p>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div
						className="col-xl-6 col-lg-6 col-12"
						data-aos="fade-up"
						data-aos-delay="300"
					>
						<div className="about-image-area about-shape-animation right-0 mr-0">
							<div className="about-image js-tilt">
								<Link to={process.env.PUBLIC_URL + "/төсөл-хөтөлбөр"}>
									<Tilt scale={scale} transitionSpeed={4000}>
										<img
											src={process.env.PUBLIC_URL + "images/project/map.jpg"}
											alt="map"
										/>
									</Tilt>
								</Link>
							</div>
							<div
								className="shape shape-1"
								id="scene"
								ref={sceneEl}
							>
								<span data-depth="1">
									<img
										src={
											process.env.PUBLIC_URL +
											"images/shape-animation/video-shape-1.png"
										}
										alt=""
									/>
								</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default HomeAboutTwo;
