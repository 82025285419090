import { useState, useEffect, useRef } from "react";
import SectionTitleTwo from "../../components/SectionTitles/SectionTitleTwo";
// import { Link } from "react-router-dom";
import Tilt from "react-parallax-tilt";
import Parallax from "parallax-js";

const AboutSix = () => {
	const [scale] = useState(1.04);
	const sceneEl = useRef(null);

	useEffect(() => {
		const parallaxInstance = new Parallax(sceneEl.current, {
			relativeInput: true,
		});

		parallaxInstance.enable();

		return () => parallaxInstance.disable();
	}, []);
	return (
		<div className="section section-padding-top">
			<div className="container">
				<div className="row">
					<div className="col-xl-7 col-lg-6 col-12" data-aos="fade-up">
						<div className="about-image-area">
							<Tilt scale={scale} transitionSpeed={4000}>
								<img
									src={process.env.PUBLIC_URL + "images/about/service-4.png"}
									alt=""
								/>
							</Tilt>
							<div className="shape shape-1" id="scene" ref={sceneEl}></div>
						</div>
					</div>

					<div
						className="col-xl-5 col-lg-6 col-12"
						data-aos="fade-up"
						data-aos-delay="300"
					>
						<div className="about-content-area">
							<SectionTitleTwo title="JCI Mongolia байгууллагын бүтэц болон гишүүдийн хувийн мэдээлэл" />

							<p>
								Байгууллага бүтэц болоод гишүүдийн мэдээллийг багтаана.
								Ингэснээр гишүүд бүр хамт олонтой чөлөөтэй холбогдох, таньж
								мэдэх боломж бүрдэнэ.
							</p>
							<ul className="mt-4 list-margin">
								<li>Байгууллагын бүтэц</li>
								<li>Хувийн мэдээлэл</li>
								<li>
									Гишүүдийн сонирхол – бизнесийн буюу ажил хэргийн болон бусад
									хобби
								</li>
								<li>Custom properties</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AboutSix;
