import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import { getData } from "../../utils/getData";

const BlogItem = ({ data }) => {
	return (
		<div className="blog">
			<div className="thumbnail">
				<Link
					to={process.env.PUBLIC_URL + `/corporate-details/${data.id}`}
					className="image"
				>
					<img src={`${getData(data._embedded, "image")}`} alt="Image" />
				</Link>
			</div>
			<div className="info">
				<h3 className="title">
					<Link to={process.env.PUBLIC_URL + `/corporate-details/${data.id}`}>
						{data && data.title.rendered}
					</Link>
				</h3>
			</div>
		</div>
	);
};

BlogItem.propTypes = {
	data: PropTypes.object,
};

export default BlogItem;
