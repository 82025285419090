import React from "react";
import { useEffect, useRef } from "react";
import contactDataTwo from "../../data/contactInfo/contactInfoTwo.json";
import ContactInfoItem from "../../components/ContactInfo/ContactInfoItemTwo.jsx";
// import SectionTitle from "../../components/SectionTitles/SectionTitle";
import SectionTitleTwo from "../../components/SectionTitles/SectionTitleTwo";
// import ProjectForm from "../../components/ProjectForm/ProjectForm";
import Parallax from "parallax-js";

const ContactInformationTwo = () => {
	const sceneEl = useRef(null);

	useEffect(() => {
		const parallaxInstance = new Parallax(sceneEl.current, {
			relativeInput: true,
		});

		parallaxInstance.enable();

		return () => parallaxInstance.disable();
	}, []);

	return (
		<div className="section section-padding contact-section bg-color-1">
			<div className="container">
				<div className="row row-cols-lg-2 row-cols-1 align-items-center">
					<div className="col" data-aos="fade-up">
						<div className="contact-Information mr-xl-7">
							<SectionTitleTwo title="Бидэнтэй холбоо барина уу" />
							{contactDataTwo &&
								contactDataTwo.map((single, key) => {
									return (
										<React.Fragment key={key}>
											<ContactInfoItem
												classOption="ct-info-two"
												data={single}
												key={key}
											/>
										</React.Fragment>
									);
								})}
						</div>
					</div>
					<div className="col mt-lg-0 mt-md-10 mt-8" data-aos="fade-up">
						<div className="jci-map">
							<iframe
								src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10704.709517502675!2d106.9148483!3d47.8748929!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xd27d4514a317138f!2sJCI%20Mongolia%20Office!5e0!3m2!1sen!2smn!4v1639367907736!5m2!1sen!2smn"
								width="760"
								height="450"
								style={{ border: 0 }}
								allowFullScreen=""
								loading="lazy"
								className="jci-google-map"
							/>
						</div>
						<div className="shape shape-1" id="scene" ref={sceneEl}>
							<span data-depth="1">
								<img
									src={
										process.env.PUBLIC_URL +
										"/images/shape-animation/contact-shape.png"
									}
									alt=""
								/>
							</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ContactInformationTwo;
