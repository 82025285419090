import React from "react";
import { createRoot } from "react-dom";
import App from "./App";
import { DataProvider } from "./context";

const root = document.getElementById("root");
const reactRoot = createRoot(root);

reactRoot.render(
  <DataProvider>
    <App />
  </DataProvider>
);
