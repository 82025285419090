import React, { useContext } from "react";
import SEO from "../components/SEO";
import Header from "../partials/header/Header";
import Breadcrumb from "../container/Breadcrumb/Breadcrumb";
import EventComponent from "../components/EventComponent";
import Footer from "../container/Footer/Footer";
import ScrollToTop from "../components/ScrollToTop.jsx";
import { DataContext } from "../context";

const Event = () => {
  const value = useContext(DataContext);
  // const data = value.jciCorPost.filter((item) => item.title.rendered === "JCI");

  return (
    <>
      <SEO title="JCI байгууллага" />
      <Header />
      <Breadcrumb
        image="images/bg/jci-mongolia.png"
        title={value.eventPage[0]?.title.rendered}
        content="Home"
        contentTwo={value.eventPage[0]?.title.rendered}
      />
      <EventComponent data={value.eventPage[0]} />
      <Footer />
      <ScrollToTop />
    </>
  );
};

export default Event;
