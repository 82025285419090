import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { DataContext } from "../../context";

const SidebarPost = () => {
	const value = useContext(DataContext);

	return (
		<div className="sidebar-widget-content">
			<ul className="sidebar-widget-list-post">
				{value &&
					value.news.slice(0, 4).map((post) => {
						return (
							<li key={post.id}>
								<Link to={process.env.PUBLIC_URL + `/blog-details/${post.id}`}>
									<div
										dangerouslySetInnerHTML={{
											__html: post && post.title.rendered,
										}}
									/>
								</Link>
							</li>
						);
					})}
			</ul>
		</div>
	);
};

export default SidebarPost;
