import React from "react";
import SEO from "../components/SEO";
import Header from "../partials/header/Header";
import Breadcrumb from "../container/Breadcrumb/Breadcrumb";
import BlogClassicContainer from "../container/BlogGrid/BlogClassicContainer";
import Footer from "../container/Footer/Footer";
import ScrollToTop from "../components/ScrollToTop.jsx";

const BlogClassic = () => {
	return (
		<React.Fragment>
			<SEO title="Мэдээ" />
			<Header />
			<Breadcrumb
				image="images/bg/news-banner.jpg"
				title="Манай бүх сүүлийн үеийн мэдээ, ойлголт, үйл явдлыг олж мэдээрэй"
				content="Home"
				contentTwo="Мэдээ мэдээлэл"
			/>
			<BlogClassicContainer />
			<Footer />
			<ScrollToTop />
		</React.Fragment>
	);
};

export default BlogClassic;
