import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { getData } from "../../utils/getData";

const Intro = ({ data }) => {
	const filterProject = data.categories.find((category) => category === 11);
	return (
		<div
			className="intro-section overlay section"
			style={{
				backgroundImage: `url(${
					process.env.PUBLIC_URL + getData(data._embedded, "image")
				})`,
			}}
		>
			<div className="container">
				<div className="row row-cols-lg-1 row-cols-1">
					<div className="col align-self-center">
						<div className="intro-content mt-xl-8 mt-lg-8 mt-md-8 mt-sm-8 mt-xs-8">
							<div
								className="title"
								dangerouslySetInnerHTML={{ __html: data.title.rendered }}
							/>
							<div className="desc">
								<div
									dangerouslySetInnerHTML={{ __html: data.excerpt.rendered }}
								/>
							</div>
							<Link
								to={
									filterProject
										? process.env.PUBLIC_URL + `/work-details/${data.id}`
										: process.env.PUBLIC_URL + `/blog-details/${data.id}`
								}
								className="btn btn-outline-white btn-hover-primary"
							>
								{" "}
								Дэлгэрэнгүй{" "}
							</Link>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

Intro.propTypes = {
	data: PropTypes.object,
};

export default Intro;
