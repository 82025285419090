import React from "react";
import SEO from "../components/SEO";
import Header from "../partials/header/Header";
import IntroTwo from "../container/IntroSlider/IntroTwo";
import IntroSlider from "../container/IntroSlider/IntroSlider";
import HomeAboutTwo from "../components/About/HomeAboutTwo.jsx";
import Funfact from "../container/Funfact/Funfact";
import ServiceIconBox from "../container/service/ServiceIconBox";
import HomeSkillWithVideo from "../container/HomeSkillWithVideo/HomeSkillWithVideo";
import ContactInformationTwo from "../container/ContactInformation/ContactInformationTwo";
import Footer from "../container/Footer/Footer";
import ScrollToTop from "../components/ScrollToTop.jsx";

const HomeTwo = () => {
	return (
		<React.Fragment>
			<SEO title="JCI Mongolia" />
			<Header />
			<IntroSlider />
			<IntroTwo />
			<HomeAboutTwo />
			<Funfact />
			<ServiceIconBox classOption="bg-color-1" />
			<HomeSkillWithVideo />
			<ContactInformationTwo />
			<Footer />
			<ScrollToTop />
		</React.Fragment>
	);
};

export default HomeTwo;
