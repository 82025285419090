import React from "react";
import SEO from "../components/SEO";
import Header from "../partials/header/Header";
import Breadcrumb from "../container/Breadcrumb/Breadcrumb";
import WorkContainer from "../container/Work/WorkContainer";
import Footer from "../container/Footer/Footer";
import ScrollToTop from "../components/ScrollToTop.jsx";

const Work = () => {
	return (
		<React.Fragment>
			<SEO title="Төсөл хөтөлбөр" />
			<Header />
			<Breadcrumb
				image="images/bg/newsletter.jpg"
				title="ТӨСӨЛ ХӨТӨЛБӨРҮҮД"
				content="Home"
				contentTwo="Төсөл хөтөлбөр"
			/>
			<WorkContainer />
			<Footer />
			<ScrollToTop />
		</React.Fragment>
	);
};

export default Work;
