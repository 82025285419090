import React from "react";
import { NavLink } from "react-router-dom";

const NavBar = () => {
	return (
		<nav className="site-main-menu">
			<ul>
				<li>
					<NavLink to={process.env.PUBLIC_URL + "/мэдээ-мэдээлэл"}>
						<span className="menu-text">Мэдээ мэдээлэл</span>
					</NavLink>
				</li>
				<li>
					<NavLink to={process.env.PUBLIC_URL + "/гишүүдийн-aпп"}>
						<span className="menu-text">Гишүүдийн Апп</span>
					</NavLink>
				</li>
				<li>
					<NavLink to={process.env.PUBLIC_URL + "/төсөл-хөтөлбөр"}>
						<span className="menu-text">Төсөл хөтөлбөр</span>
					</NavLink>
				</li>
				<li>
					<NavLink to={process.env.PUBLIC_URL + "/jci-corporate"}>
						<span className="menu-text">JCI Байгууллага</span>
					</NavLink>
				</li>
				<li>
					<NavLink to={process.env.PUBLIC_URL + "/jci-монгол-байгууллага"}>
						<span className="menu-text">JCI Монгол байгууллага</span>
					</NavLink>
				</li>
			</ul>
		</nav>
	);
};

export default NavBar;
