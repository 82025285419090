import { useState, useEffect, useRef } from "react";
import SectionTitleTwo from "../../components/SectionTitles/SectionTitleTwo";
import Tilt from "react-parallax-tilt";
import Parallax from "parallax-js";

const AboutFive = () => {
	const [scale] = useState(1.04);
	const sceneEl = useRef(null);
	useEffect(() => {
		const parallaxInstance = new Parallax(sceneEl.current, {
			relativeInput: true,
		});

		parallaxInstance.enable();

		return () => parallaxInstance.disable();
	}, []);

	return (
		<div className="section section-padding-top bg-primary-blue">
			<div className="container">
				<div className="row row-cols-lg-2 row-cols-1 mb-n6">
					<div
						className="col-xl-5 col-lg-6 col-12"
						data-aos="fade-up"
						data-aos-delay="300"
					>
						<div className="about-content-area">
							<SectionTitleTwo
								subTitle="Нэг байгууллага, нэг суваг, нэг чат"
								title="Чат"
							/>

							<p>
								JCI Mongolia байгууллагын үйл ажиллагаа, гишүүдийн мэдээлэл
								солилцох нэг төрлийн урсгал болоод орчинг бүрдүүлснээр
								мэдээллийн замбараагүй байдлыг арилгах болно..
							</p>
							<p className="mt-2">Чат сувгууд</p>
							<ul className="mt-4 list-margin">
								<li>Грүпп чат- салбар, бизнес, хобби</li>
								<li>Хувийн чат</li>
								<li>Хамгийн их ашигладаг чат /favorite/</li>
							</ul>
						</div>
					</div>

					<div
						className="col mb-6 pl-xl-12"
						data-aos="fade-up"
						data-aos-delay="300"
					>
						<div className="about-image-area faq-image-area">
							<Tilt scale={scale} transitionSpeed={4000}>
								<img
									src={process.env.PUBLIC_URL + "/images/about/service-5.png"}
									alt=""
								/>
							</Tilt>
							<div className="shape shape-1" id="scene" ref={sceneEl}></div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AboutFive;
