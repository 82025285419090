import { useState, useEffect, useRef } from "react";
import SectionTitleTwo from "../../components/SectionTitles/SectionTitleTwo";
import Tilt from "react-parallax-tilt";
import Parallax from "parallax-js";

const AboutSix = () => {
	const [scale] = useState(1.04);
	const sceneEl = useRef(null);

	useEffect(() => {
		const parallaxInstance = new Parallax(sceneEl.current, {
			relativeInput: true,
		});

		parallaxInstance.enable();

		return () => parallaxInstance.disable();
	}, []);
	return (
		<div className="section section-padding-top">
			<div className="container">
				<div className="row">
					<div className="col-xl-7 col-lg-6 col-12" data-aos="fade-up">
						<div className="about-image-area">
							<Tilt scale={scale} transitionSpeed={4000}>
								<img
									src={process.env.PUBLIC_URL + "images/about/service-3.png"}
									alt=""
								/>
							</Tilt>
							<div className="shape shape-1" id="scene" ref={sceneEl}></div>
						</div>
					</div>

					<div
						className="col-xl-5 col-lg-6 col-12"
						data-aos="fade-up"
						data-aos-delay="300"
					>
						<div className="about-content-area">
							<SectionTitleTwo
								subTitle="JCI Academy хамтдаа хөгжиж, хамтдаа суралцах орон зай"
								title="JCI Academy"
							/>

							<p>
								JCI Academy-аас санал болгож буй сургалтууд, тэдгээрийг заах
								сургагч багш нарын талаарх мэдээллээс гадна гишүүд өөрийн
								сурсан, мэдсэн зүйлсээ хамт олонтойгоо хуваалцаж байгууллагынхаа
								хөгжилд үнэ цэнтэй хувь нэмэр оруулах эерэг туршлага бүтээнэ.
							</p>
							<ul className="mt-4 list-margin">
								<li>JCI Academy-ын сургалтууд</li>
								<li>Подкаст</li>
								<li>Видео</li>
								<li>Судалгаа</li>
								<li>Гарын авлага</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AboutSix;
