import React, { useState } from "react";
import ModalVideo from "react-modal-video";

const HomeSkillWithVideo = () => {
	const [isOpen, setOpen] = useState(false);

	return (
		<div className="video-section section section-padding more-spacing">
			<div className="container">
				<div className="row align-items-center">
					<div className="col-xl-5 col-lg-6 col-12" data-aos="fade-up">
						<div className="skill-with-video-content">
							<div className="section-title-two mb-8">
								<h3 className="title">
									JCI байгууллагын эрхэм зорилго бол “Эерэг өөрчлөлтийг бүтээх
									хөгжлийн боломжийг залуучуудад олгох”.
								</h3>
							</div>

							<ul className="agency-list">
								<li className="item">
									<div className="icon">
										<i className="fas fa-check"></i>
									</div>
									<div className="text">ХУВЬ ХҮНИЙ ХӨГЖИЛ</div>
								</li>
								<li className="item">
									<div className="icon">
										<i className="fas fa-check"></i>
									</div>
									<div className="text">НИЙГМИЙН ОРОЛЦОО</div>
								</li>
								<li className="item">
									<div className="icon">
										<i className="fas fa-check"></i>
									</div>
									<div className="text">ОЛОН УЛСЫН ХАМТЫН АЖИЛЛАГАА</div>
								</li>
								<li className="item">
									<div className="icon">
										<i className="fas fa-check"></i>
									</div>
									<div className="text">БИЗНЕСИЙН БОЛОМЖ</div>
								</li>
							</ul>
						</div>
					</div>

					<div className="offset-xl-1 col-xl-6 col-lg-6 col-12">
						<div className="video-popup-area">
							<div className="skill-video">
								<img
									className="image"
									src={process.env.PUBLIC_URL + "/images/video/jci-video.png"}
									alt="video popup"
								/>
								<button className="icon" onClick={() => setOpen(true)}>
									<i className="fas fa-play"></i>
								</button>
								<ModalVideo
									channel="youtube"
									autoplay
									isOpen={isOpen}
									videoId="Ym0NHjSzwJk"
									onClose={() => setOpen(false)}
								/>
							</div>

							<div className="shape shape-1 scene">
								<span data-depth="1">
									<img
										src={
											process.env.PUBLIC_URL +
											"/images/shape-animation/video-shape-1.png"
										}
										alt=""
									/>
								</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default HomeSkillWithVideo;
