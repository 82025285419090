import PropTypes from "prop-types";
import React from "react";
import { getData } from "../../utils/getData";

const WorkDetailsContainer = ({ data }) => {
	return (
		<div className="section section-padding">
			<div className="container">
				<div className="row pt--100 pb--80">
					<div className="col-lg-4 col-md-6 col-12">
						<div className="work-left work-details" data-aos="fade-up">
							<div className="portfolio-main-info">
								<div className="work-details-list mt-12">
									<div className="details-list">
										<label>Эхлэл огноо – Дуусах огноо</label>
										<span>{data && data.acf && data.acf.date}</span>
									</div>

									<div className="details-list">
										<label>Байршил</label>
										<span>{data && data.acf && data.acf.location}</span>
									</div>

									<div className="details-list">
										<label>Төслийн төсөв</label>
										<span>{data && data.acf && data.acf.budget}</span>
									</div>

									<div className="details-list">
										<label>Хэрэгжсэн чиглэл</label>
										<span>{data && data.acf && data.acf.purpose}</span>
									</div>

									<div className="details-list">
										<label>Салбар</label>
										<span>{data && data.acf && data.acf.branch}</span>
									</div>

									<div className="details-list">
										<label>Ангилал</label>
										<span>{data && data.acf && data.acf.category}</span>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="col-lg-7 col-md-6 offset-lg-1 col-12">
						<div className="work-left work-details mt-6">
							<div className="work-main-info">
								<div className="work-content">
									<div className="desc mt-8">
										<div
											dangerouslySetInnerHTML={{
												__html: data && data.content.rendered,
											}}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="row">
					<div className="col-lg-12">
						<div
							className="custom-column-thumbnail mt-lg-14 mt-1"
							data-aos="fade-up"
						>
							<img
								className="w-100"
								src={`${getData(data && data._embedded, "image")}`}
								alt="Agency"
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

WorkDetailsContainer.propTypes = {
	data: PropTypes.object,
};

export default WorkDetailsContainer;
