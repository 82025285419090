import PropTypes from "prop-types";
import React, { useState, useContext } from "react";
import BlogClassic from "../../components/Blog/BlogClassic.jsx";
import SidebarWrap from "../../components/Sidebar/SidebarWrap.jsx";
import SidebarWidget from "../../components/Sidebar/SidebarWidget.jsx";
import SidebarTitle from "../../components/Sidebar/SidebarTitle";
import SidebarPost from "../../components/Sidebar/SidebarPost.jsx";
import Pagination from "../../components/Pagination/Pagination.jsx";
import { DataContext } from "../../context";

const BlogClassicContainer = () => {
	const [currentPage, setCurrentPage] = useState(1);
	const [postsPerPage] = useState([3]);
	const value = useContext(DataContext);

	// Get current posts
	const indexOfLastPost = currentPage * postsPerPage;
	const indexOfFirstPost = indexOfLastPost - postsPerPage;

	// Change Page
	const paginate = (pageNumber) => setCurrentPage(pageNumber);

	return (
		<div className="section section-padding fix">
			<div className="container">
				<div className="row mb-n10">
					<div className="col-lg-8 col-12 order-lg-1 mb-10">
						<div className="row row-cols-1 no-gutters">
							{value &&
								value.news
									.slice(indexOfFirstPost, indexOfLastPost)
									.map((single) => {
										return (
											<div
												key={single.id}
												className="col mb-6"
												data-aos="fade-up"
											>
												<BlogClassic data={single} />
											</div>
										);
									})}
						</div>
						<Pagination
							postsPerPage={postsPerPage}
							totalPosts={value.news.length}
							paginate={paginate}
						/>
					</div>

					<div className="col-lg-4 col-12 order-lg-2 mb-10">
						<SidebarWrap>
							<SidebarWidget>
								<SidebarTitle classOption="mb-2" title="Recent Posts" />
								<SidebarPost />
							</SidebarWidget>
							<div className="facebook-widget">
								<iframe
									src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com/jcimongolia%2Ffacebook&tabs=timeline&width=340&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId"
									width="340"
									height="500"
									style={{ border: "none", overflow: "hidden", marginTop: 60 }}
									scrolling="no"
									frameBorder="0"
									allowFullScreen={true}
									allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
								/>
							</div>
						</SidebarWrap>
					</div>
				</div>
			</div>
		</div>
	);
};

BlogClassicContainer.propTypes = {
	data: PropTypes.object,
};

export default BlogClassicContainer;
