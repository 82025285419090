import React, { Component } from "react";
import WPAPI from "wpapi";
import axios from "axios";
import Config from "./config";
import PropTypes from "prop-types";

const wp = new WPAPI({ endpoint: Config.apiUrl });

const DataContext = React.createContext();

class DataProvider extends Component {
  state = {
    news: [],
    highlightNews: [],
    videoBackground: [],
    projectPosts: [],
    jciCorPost: [],
    jciMonCorPost: [],
    eventPage: {},
  };

  componentDidMount() {
    this.getDatas();
  }

  getDatas() {
    const newsFeed = wp.posts().categories(6).perPage(100).embed();
    const highlightNewsFeed = wp.posts().categories(12).perPage(100).embed();
    const videoBack = wp.posts().categories(9).embed();
    const projects = wp.posts().categories(11).perPage(100).embed();
    const jciCorporate = wp.posts().categories(7).perPage(100).embed();
    const jciMongoliaCorporate = wp.posts().categories(8).perPage(100).embed();
    const eventPage = wp.pages().slug("event").embed();

    axios.get(newsFeed).then((res) => {
      this.setState({
        news: res.data,
      });
    });
    axios.get(highlightNewsFeed).then((res) => {
      this.setState({
        highlightNews: res.data,
      });
    });
    axios.get(videoBack).then((res) => {
      this.setState({
        videoBackground: res.data,
      });
    });
    axios.get(projects).then((res) => {
      this.setState({
        projectPosts: res.data,
      });
    });
    axios.get(jciCorporate).then((res) => {
      this.setState({
        jciCorPost: res.data,
      });
    });
    axios.get(jciMongoliaCorporate).then((res) => {
      this.setState({
        jciMonCorPost: res.data,
      });
    });
    axios.get(eventPage).then((res) => {
      this.setState({
        eventPage: res.data,
      });
    });
  }

  render() {
    console.log(this.state.eventPage);
    return (
      <DataContext.Provider value={{ ...this.state }}>
        {this.props.children}
      </DataContext.Provider>
    );
  }
}

const DataConsumer = DataContext.Consumer;

DataProvider.propTypes = {
  children: PropTypes.any,
};

export { DataProvider, DataConsumer, DataContext };
