import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import { getData } from "../../utils/getData";

const WorkItemTwo = ({ data }) => {
	return (
		<div className="work">
			<div className="thumbnail">
				<Link
					className="image"
					to={process.env.PUBLIC_URL + `/work-details/${data.id}`}
				>
					<img src={`${getData(data._embedded, "image")}`} alt="work" />
				</Link>
			</div>
			<div className="info">
				<h3 className="title">
					<Link to={process.env.PUBLIC_URL + `/work-details/${data.id}`}>
						{data.title.rendered}
					</Link>
				</h3>
				<div className="desc">
					<div
						dangerouslySetInnerHTML={{
							__html: data.excerpt.rendered,
						}}
					/>
				</div>
			</div>
		</div>
	);
};

WorkItemTwo.propTypes = {
	data: PropTypes.object,
};

export default WorkItemTwo;
