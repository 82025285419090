import React, { useContext } from "react";
import YoutubeBackground from "react-youtube-background";
import { Link } from "react-router-dom";
import { DataContext } from "../../context";

const IntroTwo = () => {
  const value = useContext(DataContext);
  const post = value.videoBackground[0];
  return (
    <YoutubeBackground
      videoId="tn0ZuPJqmjg"
      overlay="rgba(0,0,0,.4)"
      className="intro-section section bg-video"
      playerOptions={{ loop: 1 }}
    >
      {value &&
        value.videoBackground &&
        Array.isArray(value.videoBackground) &&
        value.videoBackground.length && (
          <div key={post.id} className="container">
            <div className="row row-cols-lg-1 row-cols-1">
              <div className="col align-self-center">
                <div className="intro-content-two text-center mt-xl-8 mt-lg-8 mt-md-8 mt-sm-8 mt-xs-8">
                  <h2 className="title">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: post.title.rendered,
                      }}
                    />
                  </h2>
                  <div className="desc">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: post.excerpt.rendered,
                      }}
                    />
                  </div>
                  <Link
                    to={process.env.PUBLIC_URL + "/мэдээ-мэдээлэл"}
                    className="btn btn-outline-white btn-hover-primary"
                  >
                    {" "}
                    Дэлгэрэнгүй{" "}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        )}
    </YoutubeBackground>
  );
};

export default IntroTwo;
